var $_SLIDER = {};

$_SLIDER.registration = {
    centeredSlides: true,
    slidesPerView: 'auto',
    //observer: true,
    navigation: {
        nextEl: '.registration-slider__arrow_next',
        prevEl: '.registration-slider__arrow_prev',
        disabledClass: 'registration-slider__arrow_disabled'
    },
};

export { $_SLIDER };
