import { $_PGW } from './pgw.mjs';
var $_CONFIG = $_CONFIG || {};

$_CONFIG.apiVersion = 'v2';
$_CONFIG.swLogger = false;
$_CONFIG.sport = true;
$_CONFIG.sportVersion = 3;
$_CONFIG.sportId = 'wazamba';
$_CONFIG.sportWidgetSkinName = 'wazamba';
$_CONFIG.pgw = $_PGW;

$_CONFIG.pwa = true;
$_CONFIG.appName = 'Wazamba';
$_CONFIG.mobile = true;
$_CONFIG.themeColor = '#721dac';
$_CONFIG.currentType = 'casino'; // casino || sport

$_CONFIG.breakpoints = {
    bp1: 540,
    bp2: 768,
    bp3: 1024
};

$_CONFIG.scripts = $_CONFIG.scripts || [];

$_CONFIG.domain = 'https://wazamba.devv2.space';

$_CONFIG.googleAnalytics = { appId: 'UA-131568742-1', trackAs: 'wazamba' };

$_CONFIG.cdn = '/joxi';
$_CONFIG.siteDomain = 'https://m.wazamba.com';
$_CONFIG.siteName = 'wazamba';
if (typeof window !== 'undefined') {
  $_CONFIG.feature = { ...window?.$_CDN?.feature || {}, league_tournament: {visible: true} };
}


$_CONFIG.swiper = true;
$_CONFIG.useSvgSprite = true;
$_CONFIG.redirectToNotFound = false;
$_CONFIG.libphonenumber = true;

$_CONFIG.avatars = {
    '0': { name: 'advar' },
    '1': { name: 'bomani' },
    '2': { name: 'chimola' }
};

$_CONFIG.symplify = true;
//- ПЛЕЙСИ ДЛЯ БАНЕРІВ І СЛАЙДЕРІВ
//- в шаблоні вказувати саме об'єкт конфіга.
$_CONFIG.places = {
  banner: {
    login: `'login_banner'`,
    registration: `'registration_banner'`,
    home1: `'sport_main_banner'`,
    home2: `'paynplay_pay'`,
    home3: `'sport_promotion'`,
    home4: `'live_promotion'`,
    home5: `'casino_promotion'`,
    home6: `'collection_main_banner'`,
    entrance_bnr1: `'entrance_1'`,
    entrance_bnr2: `'gamehall'`,
    entrance_bnr3_1: `'entrance_3_1'`,
    entrance_bnr3_2: `'entrance_3_2'`,
    entrance_bnr3_3: `'entrance_3_3'`,
    entrance_tournament: `'entrance_tournament'`,
    inframe_banner1_1: `'inframe_banner1'`,
    inframe_banner1_2: `'inframe_banner2'`,
    inframe_banner1_3: `'inframe_banner3'`,
    entrance_bnr3: `'entrance_3'`,
    entrance_bnr4: `'entrance_4'`,
    entrance_bnr5: `'entrance_5'`,
    gamehall_challenges: `'gamehall_challenges'`,
  },
  slider: {
    gamehall1: `'gamehall'`, //for video banner in game hall page
    gamehall_slider: `'gamehall_slider'`,

  },
  promo: {
    registration: `'welcome_bonus'`,
    home1: `'sport_main'`,
    home2: `'casino_main'`,
    home3: `'g.casino'`,
    card_guide: `'collection_guide'`
  },
  icons: {
    footer1: `'footer_pay'`,
    paynplay: `'paynplay_pay'`
  }
};

$_CONFIG.banners = {
  bottomRight: {
    url: 'https://joxi.imgsrcdata.com/rabona/sports-banner-frame/{lang}/right-banner1/{lang}1.jpg',
    link: 'https://rabona.com/{lang}/champions-cup'
  }
};

$_CONFIG.sportBanners = {
  topCenter: {
    iframe: `/sport-banners-slider/`,
    options: {
      pages: ['prelive'],
      height: '190px'
    }
  },
  aboveHighlights: {
    iframe: '/sport-banners-slider/live-stream/',
    options: {
      pages: ['prelive'],
      height: '280px'
    }
  },
  bottomLeft: {
    iframe: '/sport-leaderboard/',
    options: {
      pages: ['prelive'],
      height: '168px'
    }
  }
};

$_CONFIG.scss = {
    test: 'test'
};

$_CONFIG.places = {
    banner: {
        registration: `'registration_welcome_banner'`
    }
};

$_CONFIG.bonusSuccessAlias = {
  exchange: 'casinoTop',
  casino: 'casinoTop',
  freebet: 'sport',
  sport: 'sport',
  freespin: 'realGame',
};

if (typeof window !== 'undefined') {
  window.$_REGISTRATION = {};
  window.$_REGISTRATION.promotions = {
    category: 'welcome_bonus',
    active: 0,
  };
  window.$_REGISTRATION.avatars = {
    active: 1,
  };
}

export { $_CONFIG };
