const router = [

    //main layout with header
    {
        name: 'app.root',
        abstract: true,
        template: '<linda-app show-league-popup-ws dvs-notification-not-verified class="lindaApp"/>'
    },
    {
        name: 'app.root.layer',
        abstract: true,
        template: '<linda-view-layer-four/>'
    },
    {
        name: 'app.root.game_layer',
        abstract: true,
        template: '<linda-view-layer-five class="game-page-layer"/>'
    },
    {
        name: 'app.root.layer.l1',
        abstract: true,
        template: '<linda-view-layer-three/>'
    },
    //start screen
    {
        name: 'app.root.layer.home',
        url: '/',
        template: '<linda-homepage />',
    },
    {
        name: 'app.root.layer.fastdep',
        url: '/fast-deposit',
        template: '<linda-zimpler-paynplay-page />',
    },

    {
        name: 'app.root.layer.registration',
        url: '/registration',
        template: '<linda-registration-page redirect="\'app.root.layer.home\'"></linda-registration-page>'
    },


    // todo new router start
    {
        name: 'app.root.game_layer.game',
        abstract: true,
        template: '<linda-game-page>',
    },
    {
        name: 'app.root.game_layer.game.demo',
        url: '/game/:name',
        params: {
            mode: 'demo',
        },
    },
    {
        name: 'app.root.game_layer.game.real',
        url: '/play/:name',
        private: true,
        params: {
            mode: 'real',
        },
    },
    // todo new router end

    //--SPORT--
    {
        name: 'app.root.layer.sportlayer',
        abstract: true,
        template: '<batman-view-layer8 scroll-to-top></batman-view-layer8>'
    },

    {
        name: 'app.root.layer.sportlayer.horse_racing',
        abstract: true,
        template: '<linda-view-layer-seven scroll-to-top></linda-view-layer-seven>'
    },

    {
        name: 'app.root.layer.sportlayer.horse_racing.horse_racing',
        url: '/horse-racing',
        params: {
            category: 'horseRacing'
        },
    },

    {
        name: 'app.root.layer.sportlayer.horse_racing.harness',
        url: '/harness',
        params: {
            category: 'harness'
        },
    },

    {
        name: 'app.root.layer.sportlayer.horse_racing.greyhound',
        url: '/greyhound',
        params: {
            category: 'greyhound'
        },
    },

    {
        name: 'app.root.layer.sportlayer.horse_racing.next-to-jump',
        url: '/next-to-jump',
        params: {
            category: 'nextToJump'
        },
    },

    {
        name: 'app.root.layer.sportlayer.sport',
        abstract: true,
        template: '<linda-sport-page />'
    },
    {
        name: 'app.root.layer.sportlayer.sport.prelive',
        url: '/sport?sportids&catids&champids&tabs&eventid&betslipoddids',
        template: '',
        params: {
            page: 'prelive'
        }
    },
    {
        name: 'app.root.layer.sportlayer.sport.live',
        url: '/sport/live?sportids&catids&champids&tabs&eventid&betslipoddids',
        template: '',
        params: {
            page: 'live'
        }
    },
    {
        name: 'app.root.layer.sportlayer.sport.virtual',
        url: '/sport/virtual?sportids&catids&champids&tabs&eventid&betslipoddids',
        template: '',
        params: {
            page: 'vflm'
        }
    },

    //casino layout with toolbar
    {
        name: 'app.root.layer.casino',
        abstract: true,
        template: '<linda-view-layer-one />'
    },
    {
        name: 'app.root.layer.casino.main',
        url: '/casino',
        template: '<linda-casino-section />'
    },
    {
        name: 'app.root.layer.casino.providers',
        url: '/casino-providers',
        template: '<linda-provider-catalog />',
        params: {
            //back: 'app.root.layer.casino.games'
        }
    },
    {
        name: 'app.root.layer.casino.provider',
        url: '/casino-providers/:name',
        template: '<linda-game-hall-providers class="game-hall" />',
        params: {
            //back: 'app.root.layer.casino.games'
        }
    },
    {
        name: 'app.root.layer.casino.category',
        abstract: true,
        template: '<batman-view-layer3 />'
    },
    {
        name: 'app.root.layer.casino.category.item',
        url: '/games/:name',
        template: '<linda-game-hall />',
        params: {
            name: 'top'
        }
    },
    {
        name: 'app.root.layer.casino.live-games',
        abstract: true,
        template: '<batman-view-layer4 />',
    },
    {
        name: 'app.root.layer.casino.live-games.item',
        url: '/live-casino/:name',
        template: '<batman-game-hall1 />',
        params: {
            name: 'top-live-casino'
        }
    },
    {
        name: 'app.root.layer.casino.live-category',
        url: '/live-casino/:name',
        template: '<batman-game-hall1 />'
    },


    // <MACHINE>
    {
        name: 'app.root.claw_games',
        abstract: true,
        private: true,
        template:'<linda-game-page class="game-page-layer"></linda-game-page>'
    },
    {
        name: 'app.root.claw_games.gamepage',
        url: '/machine/:name',
        template: '<batman-ui-wrapper3 />',
        params: {
            page: 'crab'
        }
    },

    // </MACHINE>

    {
        name: 'app.root.game',
        url: '/game/:name/:mode',
        template: '<linda-game-page-mobile />'
    },
    {
        name: 'app.root.layer.casino.promo',
        abstract: true,
        template: '<batman-view-layer7 scroll-to-top="" ></batman-view-layer7>'
    },
    {
        name: 'app.root.layer.casino.promo.category',
        url: '/promotions/:filter',
        template: '<linda-promos scroll-to-top="" />',
        params: {
            type: 'casino',
            filter: 'casino'
        }
    },
    {
        name: 'app.root.layer.casino.promopage',
        url: '/promotions/:filter/:name',
        template: '<linda-promo-page scroll-to-top="" />',
        params: {
            type: 'casino',
            back: 'previous-page'
        }
    },
    {
        name: 'app.root.layer.casino.tournaments',
        url: '/tournaments',
        template: '<batman-tournament-list1 />'
    },
    {
        name: 'app.root.layer.sporttournament',
        url: '/sport-tournament',
        template: '<linda-view-layer-eight scroll-to-top="" />',
        params: {
            back: 'previous-page'
        }
    },
    {
        name: 'app.root.layer.casino.tournament',
        url: '/tournaments/:name',
        template: '<linda-tournament-page />',
        params: {
            back: 'previous-page'
        }
    },
    {
        name: 'app.root.layer.casino.mygames',
        url: '/games-favourites',
        template: '<batman-game-box-favourites2 />'
    },
    //profile pages
    {
        name: 'app.root.layer.profile',
        template: '<linda-profile-page />',
        private: true,
        abstract: true,
    },
    {
        name: 'app.root.layer.profile.hero',
        url: '/account/heroroom',
        template: '<linda-profile-staff />',
        private: true,
        params: {
            scrollTop: "off"
        }
    },
    {
        name: 'app.root.layer.profile.bonuses',
        url: '/account/bonuses',
        template: '<linda-profile-bonus-list scroll-to-left />',
        private: true,
        params: {
            scrollTop: "off"
        }
    },
    {
        name: 'app.root.layer.profile.settings',
        url: '/account/settings',
        template: '<linda-profile-form />',
        private: true,
        params: {
            scrollTop: "off"
        }
    },
    {
        name: 'app.root.layer.profile.gamehistory',
        url: '/account/history',
        template: '<linda-cashbox-history-casino />',
        private: true,
        params: {
            scrollTop: "off"
        }
    },

    {
        name: 'app.root.layer.profile.verification',
        url: '/account/verification',
        template: '<dvs-page scroll-to-left></dvs-page>',
        private: true,
        params: {
            scrollTop: "off"
        }
    },

    //info pages
    {
        name: 'app.root.layer.league',
        url: '/vip',
        template: '<linda-vip open-league-faq></linda-vip>'
    },
    {
        name: 'app.root.layer.leagueresult',
        private: true,
        url: '/league-result',
        template: '<batman-view-layer6 scroll-to-top="" open-league-faq></batman-view-layer6>'

    },
    {
        name: 'app.root.layer.leaguefaq',
        url: '/league-faq',
        template: '<batman-view-layer5 scroll-to-top="" class="league-faq"></batman-view-layer5>',
    },
    // {
    //     name: 'app.root.layer.vip',
    //     url: '/vip',
    //     template: '<linda-vip open-league-faq />'
    // },
    {
        name: 'app.root.layer.sitemap',
        url: '/sitemap',
        template: '<linda-sitemap />'
    },
    {
        name: 'app.root.layer.faq',
        url: '/faq',
        template: '<linda-info-page />',
        params: {
            name: 'faq'
        }
    },
    {
        name: 'app.root.layer.contacts',
        url: '/contact-us',
        template: '<linda-info-page />',
        params: {
            name: 'contact-us'
        }
    },
    {
        name: 'app.root.layer.terms',
        url: '/rules',
        template: '<linda-info-page />',
        params: {
            name: 'rules',
            selector: 'false'
        }
    },

    {
        name: 'app.root.layer.terms-anexcrab',
        url: '/rules-crab',
        template: '<linda-info-page />',
        params: {
            name: 'rules',
            selector: '#anex-crab',
        }
    },
    {
        name: 'app.root.layer.terms-anexshop',
        url: '/rules-shop',
        template: '<linda-info-page />',
        params: {
            name: 'rules',
            selector: '#anex-shop',
        }
    },

    {
        name: 'app.root.layer.policy',
        url: '/privacy-notice',
        template: '<linda-info-page />',
        params: {
            name: 'privacy-policy'
        }
    },
    {
        name: 'app.root.layer.cookies',
        url: '/cookie-notice',
        template: '<linda-info-page />',
        params: {
            name: 'cookies-policy'
        }
    },
    {
        name: 'app.root.layer.about',
        url: '/about',
        template: '<linda-info-page />',
        params: {
            name: 'about'
        }
    },
    {
        name: 'app.root.layer.responsible',
        url: '/responsible-gaming',
        template: '<linda-info-page />',
        params: {
            name: 'responsible-gaming'
        }
    },
    // {
    //     name: 'app.root.layer.leaguefaq',
    //     url: '/league-faq',
    //     template: '<batman-view-layer5 class="league-faq"/>',
    //     params: {
    //         back: 'app.root.layer.vip'
    //     }
    // },

    {
        name: 'app.root.layer.callback2',
        url: '/payment/status?status',
        template: '<linda-payment-callback />'
    },

    {
        name: 'app.root.layer.callback',
        url: '/payment/:act?order_id&identifier',
        template: '<linda-payment-callback />'
    },
    {
        name: 'app.root.layer.withdraw-paymentcb-success',
        url: '/withdraw-payment/success',
        template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
        params: {
            status: 'success'
        }
    },
    {
        name: 'app.root.layer.withdraw-paymentcb-fail',
        url: '/withdraw-payment/fail',
        template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
        params: {
            status: 'fail'
        }
    },
    {
        name:'app.root.layer.missed-data',
        url: '/missed-data',
        template: '<linda-missed-data-form></linda-missed-data-form>'
    },
    {
        name: 'app.root.layer.not-found',
        url: '/404',
        template: '<not-found />'
    },
    {
        name: 'app.root.layer.chat',
        url: '/chat',
        template: '<linda-view-layer-four />'
    },
    {
        name: 'app.root.layer.shop',
        abstract: true,
        template: '<linda-shop-page scroll-to-top=""/>',
    },
    {
        name: 'app.root.layer.shop.main',
        url: '/shop',
        template: '<linda-shop-public-wrapper />'
    },
    {
        name: 'app.root.layer.shop.tab',
        url: '/shop/:tab',
        template: '<linda-shop-public-wrapper />',
        params: {
            back: 'shop',
        },
    },
    {
        name: 'app.root.layer.achievements',
        url: '/achievements',
        template: '<linda-achievements-page />'
    },

    //- Mask collection
    {
        name: 'app.root.layer.mask-collection',
        abstract: true,
        template: '<ui-view></ui-view>',
    },
    {
        name: 'app.root.layer.mask-collection.list',
        url: '/mask-collections',
        template: '<linda-mask-collections-page scroll-to-top=""></linda-mask-collections-page>'
    },
    {
        name: 'app.root.layer.mask-collection.page',
        url: '/mask-collections/:name',
        template: '<linda-mask-collection-page scroll-to-top=""></linda-mask-collection-page>',
        private: true
    },
    /*
    * All payments page.
    * **/
    {
        name: 'app.root.layer.payments-page',
        url: '/payments-page',
        template: '<batman-banking-page1 scroll-to-top=""></batman-banking-page1>',
    },

    // --- SEASONS ---
    // {
    //     name: 'app.root.layer.l1.seasons',
    //     url: '/seasons/:name',
    //     template: '<linda-promo-wrapper scroll-to-top="" ></linda-promo-wrapper>'
    // },
    // {
    //     name: 'app.root.summer',
    //     url: '/seasons/summer',
    //     private: true,
    //     template: '<summer-promo-wrapper scroll-to-top="" ></summer-promo-wrapper>',
    // },
    // {
    //     name: 'app.root.summer-game',
    //     url: '/seasons-summer',
    //     private: true,
    //     template: `<summer-promo-page scroll-to-top=""></summer-promo-page>`,
    // },
];
export { router };
